
export default function cardsessao() {


	let card01 = document.querySelector(".cardSessao01")
	
	let close_card01 = document.querySelector(".close_card01")


	if(close_card01) {
		close_card01.addEventListener("click", ()=>{
			card01.classList.add("hidden")
		})

	}



	

  
}
