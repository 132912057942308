import { findAll } from "../utils/dom"; // Optional import

import Swiper from "swiper";

const swiperConnect = () => {
  const swiperElement = document.querySelector('.swiper-tela'); 
	
	console.log("Eu",swiperElement)// Select container

  const swiper = new Swiper(swiperElement, {
    // Optional parameters
    direction: 'horizontal',
		width:500,

    slidesPerView: 1,
    spaceBetween: 30,
    speed: 1260,
    effect: 'slide',

    autoplay: {
      delay: 200,
      disableOnInteraction: true,
    },
  });
};

export default swiperConnect;
