import { findAll } from "../utils/dom";
import Swiper from "swiper"



const swiperTregua = () => {


	
		const swipercont1 = new Swiper(".swiper-container-3", {
			direction: 'horizontal',
			width: 800,
			
			// 	on: {
			// 		slideChange: function() {


			// 			const svgElementLeft = document.getElementById('svgLeft');

			// 			const svgElementRigth = document.getElementById('svgRigth');


			// 			const useElement = svgElementLeft.querySelector('use');

			// 			const useElementRigth = svgElementRigth.querySelector('use');
						
						


			// 				const currentSlideIndex = swipercont1.activeIndex;
			// 				if (currentSlideIndex === 0) {
			// 						carIniEnd = 1;
			// 						const currentHref = useElement.getAttribute('xlink:href');
			// 						const newHref = currentHref.replace('_active', '');
			// 						useElement.setAttribute('xlink:href', newHref);
			// 						//console.log(carIniEnd) 
			// 				} else if (currentSlideIndex === swipercont1.slides.length - 1) {
			// 						carIniEnd = 2;
			// 						const currentHref = useElementRigth.getAttribute('xlink:href');
			// 						const newHref = currentHref.replace('_active', '');
			// 						useElementRigth.setAttribute('xlink:href', newHref); 
			// 				} else {
			// 						carIniEnd = 0; 

			// 						const currentHref = useElementRigth.getAttribute('xlink:href');
			// 						const newHref = currentHref.replace('_active', '')+ '_active';
			// 						useElementRigth.setAttribute('xlink:href', newHref); 


			// 						const currentHref2 = useElement.getAttribute('xlink:href');
			// 						const newHref2 = currentHref2.replace('_active', '') + '_active';
			// 						useElement.setAttribute('xlink:href', newHref2);
			// 				}
			// 				return carIniEnd; 
			// 		},
					
			// },


			
				// navigation: {
				// 	nextEl: '.swiper-button-next', 
				// 	prevEl: '.swiper-button-prev', 
				// },

			slidesPerView: 1, 
			spaceBetween: 30, 
			speed: 1260, 
			effect: 'slide', 
			autoplay: {
				delay: 200,
				disableOnInteraction: true,
			},
		});

		
	
		
		const swiperContainer = document.querySelector('.swiper-container-3');
		//swiperContainer.classList.add('hide-arrows');

		
	
	}


	


	


	
	

	











export default swiperTregua;