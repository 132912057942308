import axios from "axios";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"


export default function contatoFormNewsletter(){
    const contatoFormSubmit = document.querySelector('#newsletter')
    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
        event.preventDefault();
        disabledBtn()
        const form_data = {
            nome: document.querySelector('#newsletter input[name=nome]').value,
            telefone: document.querySelector('#newsletter input[name=telefone]').value,
            email: document.querySelector('#newsletter input[name=email]').value
        }
      
        const url = "/newsletter/"
        axios.post(url, form_data) 
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const contatoFormSubmit = document.querySelector('#newsletter')
        const message = response.data ? response.data.message : response.message
        Toastify({
            text: message,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },// Callback after click
          }).showToast();

        // GLOBAL.showToastify(message)
        console.log(contatoFormSubmit);
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector("#newsletter button[type=submit]")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add("disabled")
        btnSubmit.style.pointerEvents = 'none'
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove("disabled")
        btnSubmit.style.pointerEvents = 'all'
    }
}