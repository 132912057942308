import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function animacaoGsapManual(){
	gsap.registerPlugin(ScrollTrigger);

  
    function quemsomos(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#quemsomos",
                start: "top+=10px 80%", 
               
              },
            defaults:{ opacity: 0,  opacity: 0}});
    
            tl.from('#quemsomos .col-1-custom >  div  ',{y:30,opacity: 0 , stagger: 0.3})
            tl.from('#quemsomos .col-2-custom  > div ',{y:30,opacity: 0 , stagger: 0.3},0.4)
    }

    function bannerPaginas(){
        let tl = gsap.timeline({
                scrollTrigger: {
                trigger: "#quemsomos",
                start: "top+=10px 80%", 
               ease: 'power'
              },
            defaults:{ opacity: 0,  opacity: 0}}
            );

         const svgGroupElements = document.querySelectorAll('.ANIMA-SVG-BANNER .svg-group-banner');
         const svgGroupHorizontalElements = document.querySelectorAll('.ANIMA-SVG-BANNER .svg-group-banner.horizontal-svg');
         const filteredElements = Array.from(svgGroupElements).filter(element => !element.classList.contains('horizontal-svg'));
     

         tl.from(svgGroupHorizontalElements, {
            x: function(index) {
                // Alternate direction based on index
                return index % 2 === 0 ? -30 : 30;
            },
            stagger: 0.2,
            opacity: 0,
            stagger: 0.13
        }).from(filteredElements, {
            y: -30,
            stagger: 0.2,
            opacity: 0,
            stagger: 0.13
        },0);
       
        

        // tl.from(".ANIMA-SVG-BANNER  .svg-group-banner",{
        //     y:-30,
        //     stagger:0.2,
        //     opacity: 0 ,
        //     stagger: 0.13,
        //     filter: function(index, target, targets) {
        //         // Verificar se o elemento atual tem a classe '.horizontal-svg'
        //         console.log(target.classList.contains('horizontal-svg'))
        //         return target.classList.contains('horizontal-svg');

        //     }
        // })
        // .to(".ANIMA-SVG-BANNER  .horizontal-svg",{
        //     x:-30,
        //     stagger:0.2,
        //     opacity: 0 ,
        //     stagger: 0.13
        // })
    }
  
   

    quemsomos()
    bannerPaginas();
}