import axios from "axios";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"


export default function contatoForm(){
    const contatoFormSubmit = document.querySelector('#form-contato')
    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
        event.preventDefault();
        disabledBtn()
        const form_data = {
            nome: document.querySelector('#form-contato input[name=nome]').value,
            email: document.querySelector('#form-contato input[name=email]').value,
            mensagem: document.querySelector('#form-contato textarea[name=mensagem]').value
        }
        console.log(form_data);
        const url = "/fale-conosco/"
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        Toastify({
            text: message,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },// Callback after click
          }).showToast();

        // GLOBAL.showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector("#form-contato button[type=submit]")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add("disabled")
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove("disabled")
    }
}