import { findAll } from "../utils/dom";
import Swiper from "swiper"



const swiperFundadora = () => {



// 	var swiper = new Swiper('.swiper-container', {
//    slidesPerView: 1,
//    spaceBetween: 10,
//    navigation: {
//        nextEl: '.swiper-button-nex-2',
//        prevEl: '.swiper-button-prev-2',
//    },
//    pagination: {
//        el: '.swiper-pagination-2',
//        clickable: true,
//    },
	 
		
	 
	 
//  });


 const swiper = new Swiper(".swiper-idealizadora", {
	slidesPerView: 1, 
	autoHeight:true,
	pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
	navigation: {
		nextEl: '.swiper-idealizadora #scrollRightBtn', 
		prevEl: '.swiper-idealizadora #scrollLeftBtn', 
	},

	// spaceBetween: 30, 
	// speed: 1260, 
	// effect: 'coverflow',
	// 	coverflowEffect: {
	// 		rotate: 50, 
	// 		stretch: 0, 
	// 		depth: 100, 
	// 		modifier: 1, 
	// 		slideShadows: true, 
	// 	}, 
	// autoplay: {
	// 	delay: 200,
	// 	disableOnInteraction: true,
	// },
});









 

		



}









export default swiperFundadora;