export default function scrollToNextSection(){
    
    // const Tregua = find("[js-page='tregua']")
	// if (!Tregua) return

 
    let isOnTop = false

   
    
    document.addEventListener('DOMContentLoaded', () => {

        document.addEventListener('scroll', function() {
            const globalHeader = document.querySelector('#header-geral-paginas');
            if(!globalHeader ) return
            // const nextSibling = globalHeader.nextElementSibling;
            const globalHeaderHeight = globalHeader.offsetHeight;

            if(isOnTop){

            
                window.scrollTo(
                    {
                        top: globalHeaderHeight - 72,
                        behavior: 'smooth' // Isso garante que a rolagem seja suave
                })
                isOnTop= false
            }

        })
        if (!window.location.hash) {

            if( document.documentElement.scrollTop === 0){
                isOnTop = true
            }
          
        }
    });

}