import { gsap } from "gsap";
import { height } from "../utils/dom";


function animations(attr) {
  const animation = {
    "fade-up": { ypercent: 30, opacity: 0 },
    "fade-down": { ypercent: 30, opacity: 0 },
    "fade-left": { xPercent: 30, opacity: 0 },
    "fade-right": { xPercent: -30, opacity: 0 },
    "fade-up-right": { yPercent: 30, xPercent: -30, opacity: 0 },
    "fade-up-left": { yPercent: 30, xPercent: 30, opacity: 0 },
    "fade-down-right": { yPercent: -30, xPercent: -30, opacity: 0 },
    "fade-down-left": { yPercent: -30, xPercent: 30, opacity: 0 },
		"fade-opacity": {opacity: 0.9},
    "fade-zoom-in": { scale: 1.2, opacity: 0 },
    "fade-zoom-out": { scale: 0.8, opacity: 0 },
    "slide-up": { y: -100, opacity: 0 },
    "slide-down": { y: 100, opacity: 0 },
    "zoom": { scale:0 },
    // "fade-up": { y: 100, opacity: 0 },
    // "fade-down": { y: -100, opacity: 0 },
    // "fade-left": { x: 100, opacity: 0 },
    // "fade-right": { x: -100, opacity: 0 },
    // "fade-up-right": { y: 100, x: -100, opacity: 0 },
    // "fade-up-left": { y: 100, x: 100, opacity: 0 },
    // "fade-down-right": { y: -100, x: -100, opacity: 0 },
    // "fade-down-left": { y: -100, x: 100, opacity: 0 },
    // "fade-zoom-in": { scale: 1.2, opacity: 0 },
    // "fade-zoom-out": { scale: 0.8, opacity: 0 },
    // "slide-up": { y: -100, opacity: 0 },
    // "slide-down": { y: 100, opacity: 0 },
    // "zoom": { scale:0 },
  };
  return animation[attr] || { opacity: 0 };
}

/**
 * [data-from]  // objeto contendo o from da animacao
 * [data-to]    // objeto contendo o to da animacao
 * [data-anima] // nome da animacao predefinida na funcao animations
 * [data-timeline] // Se quisermos adicionar um conjunto de animacoes em uma timeline anexada ao window
 **/

export default function animateFrom(elem) {
  const dataFrom = elem.getAttribute("data-from")
    ? JSON.parse(elem.getAttribute("data-from"))
    : {};
  const dataTo = elem.getAttribute("data-to")
    ? JSON.parse(elem.getAttribute("data-to"))
    : {};
  const dataAnima = elem.getAttribute("data-anima");
  const dataTimeline = elem.getAttribute("data-timeline");

  const fromAnimation = animations(dataAnima);


  const from = {
    ...fromAnimation,
    ...dataFrom,
  };

  const to = {
    xPercent: 0,
    yPercent: 0,
    opacity: 1,
    overwrite: "auto",
    scale: 1,
    rotate: 0,
    duration: 0.3,
    ...dataTo,
    onComplete: () => elem.classList.add("animou"),
  };
console.log(elem, from , to )
  const timeline = gsap.fromTo(elem, from, to).timeScale(1);

  if (!dataTimeline) return;

  if (window[dataTimeline]) window[dataTimeline].add(timeline);
  else window[dataTimeline] = gsap.timeline().add(timeline);
}


