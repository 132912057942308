

export default function MenuFunc() {

	





	
	document.addEventListener('DOMContentLoaded', function () {
    
			const select = document.getElementById('language-select');
			let textPt = document.querySelectorAll(".text-pt");
			let textEn = document.querySelectorAll(".text-en");

			// Armazena a seleção inicial (opcional)
			let storedLanguage = localStorage.getItem('idiomaSelecionado');
			if (storedLanguage) {
				select.value = storedLanguage;
			}

			select.addEventListener('change', function () {
				localStorage.setItem('idiomaSelecionado', select.value);
				switchLanguage(select, textPt, textEn);
			});

			localStorage.setItem('idiomaSelecionado', select.value);
			switchLanguage(select, textPt, textEn);
		
	});

			

		function switchLanguage(select, text1, text2) {
				if (select.value === 'pt') {
					document.documentElement.classList.add('lang-pt')
					document.documentElement.classList.remove('lang-en')
						switchText(text2, text1);
				} else if (select.value === 'en') {
						switchText(text1, text2);
						document.documentElement.classList.add('lang-en')
						document.documentElement.classList.remove('lang-pt')
				}
		}

		function switchText(text1, text2) {
				for (let i = 0; i < Math.min(text1.length, text2.length); i++) {
						text1[i].classList.add('hidden');
						text2[i].classList.remove('hidden');
				}
		}

	

	
	



	

		


	const closeButton = document.querySelector(".close_menu")
	const menu_left = document.querySelector("#menu_left")
	const icon_close = document.querySelector(".icon_close")
	const icon_close_hover = document.querySelector(".icon_close_hover")



	//window.introHome1.pause(0, false);

	let menuTogle = document.querySelector(".menu-togle")


	menuTogle?.addEventListener("click", () => {
		menu_left.classList.add('slide-in-menu')

		const styleElement = document.createElement('style');

		// styleElement.textContent = `

		// 	.hideMenu {
		// 		opacity: 0.7;
		// 		filter: blur(2px) drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
		// 	}

		// 	`

		// document.body.appendChild(styleElement);

	})



	

	closeButton?.addEventListener('click', () => {
		menu_left.classList.remove('slide-in-menu')

		const styleElement = document.createElement('style');

		// styleElement.textContent = `
		 
		//  .hideMenu {
		// 	opacity: 1;
		// 	filter: none;
		// }
		// 	`

		// document.body.appendChild(styleElement);
	})


}