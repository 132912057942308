export default function paginaContato(){

    const tabItem = document.querySelectorAll('.tabs-contato .tab-item ')
    const contentTabs  = document.querySelectorAll('.tab-content-item')

        if(tabItem){
            tabItem.forEach(tab =>{
                tab.addEventListener('click', ()=>{
                   
                    const tabName =tab.dataset.tab


                    tabItem.forEach(i =>i.classList.remove("active"))
                    tab.classList.add('active')
                    
                    const tabcontent = document.querySelector(`#${tabName}`);

                    console.log(tabcontent)
                    if(tabcontent){
                        contentTabs.forEach(i =>i.classList.remove("active"))
                        // tabItem.forEach(i =>i.classList.remove("active"))
                        
                            tabcontent.classList.add("active");

                            tabcontent.classList.add("animatab");
                            setTimeout(() => {
                                tabcontent.classList.remove("animatab");
                            }, 500);
                       
                    }
                   
                })
            })
        }
    // tabs-contato 
}