import axios from "axios"
import { find,findAll } from "utils/dom"


export default function fetchModal(){

    const Tregua = find("[js-page='tregua']")
	if (!Tregua) return
    const destinos = findAll('.js-destino')
    

    function renderModal(data, titlePt, titleEn){
        const conteudo = find("[js-modal-content]")
        const header = find("[js-modal-header]")
        
        const dataArray = data.cards
     
        const HTMLMODAL =  dataArray.map(item => {
          
            const descPtBR = item.descricao_pt.replace(/\r?\n/g, '<br>');
            const descEnBr = item.descricao_en.replace(/\r?\n/g, '<br>');
						const label_image_pt = item.label_image_pt.replace(/\r?\n/g, '<br>');
						const label_image_en = item.label_image_en.replace(/\r?\n/g, '<br>');
						

           
            return `
            <div class="item-card-modal   card-has-image grid grid-cols-${item.imagem ? '2': '1'} sm:grid-cols-1 gap-32 sm:gap-56  sm:mb-72  mt-56">
							<div class="w-full  relative">
													${ item.referencia ?

															`<div class = " bg-[#fff]   rounded-2xl  ">
																<p class="text-pt text-paragraph-4 text-neutral-60 ">${descPtBR}</p>
																<p class="text-en hidden text-paragraph-4 text-neutral-60 ">${descEnBr}</p>
															</div>`
															:
															`<div class = "p-32 max-h-[400px]  bg-[#E4F1EA]   rounded-2xl  ">
															<p class="text-pt text-paragraph-2 ">${descPtBR}</p>
															<p class="text-en hidden text-paragraph-2 ">${descEnBr}</p>
														</div>`

													}
							</div>
                ${
                    item.imagem ? `<div class="w-full mb-24 rounded-2xl">
										<img class="w-full h-full object-cover" src="${item.imagem}" alt="">
										
										<p class="text-pt mt-4   text-paragraph-3  text-neutral-60 leading-none">${label_image_pt}</p>
										<p class="text-en  hidden mt-4 text-paragraph-3  text-neutral-60 leading-snug">${label_image_en}</p>
										
										</div>` : ''

                }
				
						</div>
						
									`
							}).join('');
 
         
        header.innerHTML = `
        <p class="text-pt text-headline-3">${titlePt.innerText}
        </p>
        <p class="text-en text-headline-3">${titleEn.innerText}
        </p>
          
			<span class="close">&times;</span>
        `
        if(data.cards.length > 0){
            conteudo.innerHTML =  HTMLMODAL
        }else{
            conteudo.innerHTML  = 'Algo deu errado , tente novamente mais tarde '
        }

    }

    destinos.forEach(item => {
            item.addEventListener('click', () =>{
                const idDestino = item.dataset.destino
                const titlePt = item.firstElementChild
                const titleEn = titlePt.nextElementSibling;
                const conteudo = find("[js-modal-content]")
                const header = find("[js-modal-header]")
                header.innerHTML = ''
                conteudo.innerHTML = `             
                    <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="200" height="200" style="shape-rendering: auto; display: block; background: transparent;" xmlns:xlink="http://www.w3.org/1999/xlink"><g><circle cx="50" cy="50" fill="none" stroke="#e4f1ea" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle><g></g></g><!-- [ldio] generated by https://loading.io --></svg>
                    `
                setTimeout(() => {
                    
                    var url = `/indice-data/${idDestino}/`
                    axios.get(url) 
                    .then((response) => {
    
                      
                        if(response.data){
                            renderModal(response.data , titlePt , titleEn)
                        }
                    })
                    .catch((response) => {console.log(response)
                        const conteudo = find("[js-modal-content]")
                        const header = find("[js-modal-header]")
                        header.innerHTML = `
                        <p class="text-pt text-headline-3">${titlePt.innerText}
                        </p>
                        <p class="text-en text-headline-3">${titleEn.innerText}
                        </p>
                          
                            <span class="close">&times;</span>
                        `
                        conteudo.innerHTML= 'Algo deu errado , tente novamente mais tarde '
                    });
                }, 300);
               
            })
    });


    function MODALZINHO(){
			const openModalBtn = document.querySelectorAll("#openModalBtn");
			const modal = document.getElementById("myModal");
			const closeBtn = document.getElementsByClassName("close")[0];
			const content = document.querySelector('.modal-content')
			const bodyElement = document.querySelector('body');
			const htmlElement = document.documentElement;
            


			


			

			openModalBtn.forEach(item => {
					item.onclick = function() {

							//console.log("aqui eu estou com vc")
							
							

							modal.style.display = "block";
							modal.scrollTop = 0;


							bodyElement.style.overflow = 'hidden';
							htmlElement.style.overflow = 'hidden'; 
					
							
							
							}
				
			})
			modal.addEventListener('click', (e) => {
					const target = e.target
					if(target.classList.contains('close')){
							modal.style.display = "none";

							 
							bodyElement.style.overflow = 'auto';
							htmlElement.style.overflow = 'auto'; 

							
							

							//console.log("Atras de vc")
					
					}else{
							return
					}
			})

			content.addEventListener('scroll', () => {
					if (content.scrollTop > 0) {
							modal.classList.add('modal-scrolou');
					} else {
							modal.classList.remove('modal-scrolou');
					}
			})

			window.onclick = function(event) {
			if (event.target == modal) {
					modal.style.display = "none";

					bodyElement.style.overflow = 'auto';
					htmlElement.style.overflow = 'auto'; 
			}
			}
	}

	
	MODALZINHO()

    // evento pra abrir o indice em  com referencia no texto 
    const linksComIndice =  document.querySelectorAll('.treguaTexto2 .richTextEditorJs a[href^="#destino-"]')
   
    linksComIndice.forEach(link => {
  
        link.addEventListener('click', (e) => {
            // Ação a ser realizada quando um link com href começando com "#indice-" é clicado
                const href= e.currentTarget.getAttribute('href')
                const idDestino = href.replace('#destino-', '')
           
                const divDestino = document.querySelector(`[data-destino="${idDestino}"]`);
                if(divDestino){
                    divDestino.click();
                }
            // Ou outra ação que desejar
        });
    });

   
}